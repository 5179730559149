import { Injectable } from '@angular/core';
import { BlacklistUsersRequest } from '@app/shared/interfaces/blacklist-users/blacklist-users.request';
import { BlacklistUser } from '@app/shared/interfaces/blacklist-users/blacklist-users.response';
import { AbstractCrudService } from '../../abstract/abstract-crud.service';

@Injectable()
export class BlacklistUsersService extends AbstractCrudService<
  BlacklistUser,
  BlacklistUsersRequest
> {
  protected override feature = 'blacklist-users';
  protected override endPoint = `/blacklist-users/`;
}
